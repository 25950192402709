<template>
  <section class="grey lighten-5 pb-5 pt-5">
    <v-container grid-list-md light text-xs-center>
      <v-layout align-center row wrap>
        <v-row justify="center">
          <v-col class="text-center pt-5 pb-5" cols="12">
            <div class="flex display-1 font-weight-light">
              1000+ Customers Worldwide
            </div>
            <div class="blockquote font-italic">
              "We try to deliver a flawless, convenient and enjoyable experience
              to our customers. Technology is helping us to deliver this
              experience by providing what the customers want and connecting
              with these customers online." states Karan
              <b>www.briosopasta.com</b>
            </div>
          </v-col>
        </v-row>
      </v-layout>
    </v-container>
    <v-container grid-list-md light text-xs-center>
      <v-layout align-center row wrap>
        <v-flex xs12>
          <v-row justify="center">
            <v-card class="elevation-0 transparent ma-5">
              <v-img
                alt="Restaurant Online Ordering Pricing"
                src="@/assets/brioso-logo.png"
                contain
                max-width="200px"
              >
              </v-img>
            </v-card>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "Clients.vue"
};
</script>

<style scoped></style>
